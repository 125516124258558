<template>
  <div>
    <div class="text-right mt-2 mr-2 dialog_con">
      <v-dialog v-model="dialog" width="100vw" class="overflow-hidden">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="#787272" dark v-bind="attrs" v-on="on">
            <v-icon size="30" v-if="showUseInstruction">
              mdi-alert-circle
            </v-icon>
          </v-btn>
        </template>
        <v-card outlined>
          <v-card-title class="text-body-1 white lighten-2 px-3 py-2">
            {{ useInstructionTitle }}
          </v-card-title>
          <v-card-text
            id="noticeContent"
            ref="noticeContent"
            :style="{ color: fontColor, fontSize: '14px' }"
            class="px-3 py-2"
          >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeNotice">
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileNotice",
  props: [
    "fontColor",
    "showUseInstruction",
    "noticeDisplay",
    "useInstructionTitle",
    "useInstruction"
  ],
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    closeNotice() {
      this.dialog = false;
    }
  },
  watch: {
    curItem() {
      this.fetchData();
    },
    dialog(v) {
      if (v === true) {
        this.$nextTick(() => {
          this.$refs.noticeContent.innerHTML = this.useInstruction;
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-dialog {
  margin-top: 0px !important;
}

/deep/ #noticeContent {
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -pre-wrap; /*Opera4-6*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
}
</style>
